(function(w, d) {
    w.maillist_signup_config.forEach(function(o) {
        var oForm = d.getElementById('mailinglist-form-' + o.block_id);
        if (o.show_select_all) {
            oForm.addEventListener('click', function(e){
                if (e.target.matches('.mailinglist-select-all')) {
                    var inputs = oForm.querySelectorAll('.mailinglist-id-'+o.block_id);
                    for (var i=0; i<inputs.length; i++) {
                        inputs[i].checked = true;
                    }
                } else if (e.target.matches('.mailinglist-select-none')) {
                    var inputs = oForm.querySelectorAll('.mailinglist-id-'+o.block_id);
                    for (var i=0; i<inputs.length; i++) {
                        inputs[i].checked = false;
                    }
                }
            });
        }
        function validateNewsletter() {   
            var oBlockID = d.getElementById('block-' + o.block_id),
                oMailingLists = oBlockID.querySelector('.mailing-lists'),
                oEmailAddress = oBlockID.querySelector('#email-' + o.block_id),
                oEmailAddressParent = oBlockID.querySelector('.email-address'),
                oEmailCheckbox = oBlockID.querySelector('.mailinglist-id-' + o.block_id),
                aListUnchecked = Array.from(oBlockID.querySelectorAll('.mailinglist-id-' + o.block_id)),
                aListID = Array.from(oBlockID.querySelectorAll('.mailinglist-id-' + o.block_id + ':checked'));  
            function validateMaillist() {   
                var oOptionsWarning = oBlockID.querySelector('.warning-list');
                if (oOptionsWarning) {
                    if (aListID == ''){
                        if (oMailingLists.classList.contains("has-error")){
                            oMailingLists.classList.remove("has-error", "text-danger");
                        }
                        oMailingLists.classList.add("has-error", "text-danger");
                        oOptionsWarning.classList.remove("hide");
                    } else {
                        oMailingLists.classList.remove("has-error", "text-danger");
                        oMailingLists.classList.add("has-success", "text-success");
                        oOptionsWarning.classList.add("hide");
                        return true
                    }
                    aListUnchecked.forEach(function(i) {
                        i.addEventListener('change', function (event) {
                            if (i.checked) {
                                    oMailingLists.classList.remove("has-error", "text-danger");
                                    oMailingLists.classList.add("has-success", "text-success");
                                    oOptionsWarning.classList.add("hide");
                            } else {
                                var aListIDRecheck = Array.from(oBlockID.querySelectorAll('.mailinglist-id-' + o.block_id + ':checked')); 
                                if (aListIDRecheck == ''){
                                    oMailingLists.classList.add("has-error", "text-danger");
                                    oMailingLists.classList.remove("has-success", "text-success");
                                    oBlockID.querySelector('.warning-list').classList.remove("hide");
                                }
                            }
                        })
                    })
                } else {
                    return true
                }
            };
            function validateEmail() {   
                oEmailAddress.addEventListener("keypress", function(event) {
                    if (event.keyCode == 64) {
                        oEmailAddressParent.classList.remove("has-error", "text-danger");
                        oEmailAddressParent.classList.add("has-success", "text-success");
                    }
                })
                if (oEmailAddress.value == '') {
                    if (oEmailAddressParent.classList.contains("has-error")){
                        oEmailAddressParent.classList.remove("has-error", "text-danger");
                    }
                    oEmailAddressParent.classList.add("has-error", "text-danger");
                    oBlockID.querySelector('.warning-email').classList.remove("hide");
                } else {
                    oEmailAddressParent.classList.remove("has-error", "text-danger");
                    oBlockID.querySelector('.warning-email').classList.add("hide");
                    return true
                }
                oEmailAddress.addEventListener("keypress", function(event) {
                    if (event.keyCode == 64) {
                        oEmailAddressParent.classList.remove("has-error", "text-danger");
                        oBlockID.querySelector('.warning-email').classList.add("hide");
                        oEmailAddressParent.classList.add("has-success", "text-success");
                    }
                })
            };
            validateMaillist()
            validateEmail()
            if (validateMaillist() && validateEmail()) {
                sendNewsletterData()
            }
        };
        function sendNewsletterData() {
            d.querySelector('#block-' + o.block_id + ' .alert-success').style.display = 'none';
            var sFormData = new FormData(),
                sXHR = new XMLHttpRequest(),
                sFormAction = oForm.getAttribute("action"),
                oBlockID = d.getElementById('block-' + o.block_id),
                sFormEmail = oBlockID.querySelector('.email').value,
                aListID = Array.from(oBlockID.querySelectorAll('.mailinglist-id-' + o.block_id + ':checked'));    
            aListID.forEach(function(i) {
                sFormData.append('list_id[]', i.value);
            })
            sFormData.append('submit', '1')
            sFormData.append('email', sFormEmail)  
            if (o.require_user_account == 'collect_name') {
                var sFormFirstName = oBlockID.querySelector('.first_name').value,
                    sFormLastName = oBlockID.querySelector('.last_name').value;
                if (sFormFirstName) {
                    sFormData.append('first_name', sFormFirstName)
                }
                if (sFormLastName) {
                    sFormData.append('last_name', sFormLastName)
                }
            }
            sXHR.onreadystatechange = function() {
                oBlockID.querySelector('.mailinglist-save .tnt-svg').style.display = 'inline-block';
                if (sXHR.readyState == 4 && sXHR.status == 200) {
                    setTimeout(function () {
                        oBlockID.querySelector('.mailinglist-save .tnt-svg').style.display = 'none';
                        oBlockID.querySelector('.alert-success').style.display = 'block';
                        oBlockID.querySelector('.user-email').innerHTML = sFormEmail;
                        oBlockID.querySelector('.email').value = '';
                        oBlockID.querySelector('.mailing-lists').classList.remove("has-success", "text-success");
                        oBlockID.querySelector('.email-address').classList.remove("has-success", "text-success");
                        aListID = Array.from(oBlockID.querySelectorAll('.mailinglist-id-' + o.block_id + ':checked'));
                        aListID.forEach(function(i) {
                            i.checked = false;
                        })
                        var oPromoPop = d.querySelector('#tncms-block-' + o.block_id + ' #promo-designer-modal-custom-pop');
                        if (oPromoPop) {
                            setTimeout(function () {
                                setTimeout(function () {
                                    oPromoPop.className += " hidden";
                                }, 500)
                                oPromoPop.className += " exit";
                            }, 3000)
                        }
                    }, 1000)
                } else if (sXHR.readyState == 4 && sXHR.status != 200) {
                    d.querySelector('#block-' + o.block_id + ' .email-signup .alert-danger').style.display = 'block';
                }
            }
            sXHR.open("POST", sFormAction);
            sXHR.send(sFormData);      
        };
        if (o.require_user_account != '1') {
            oForm.addEventListener('submit', function (event) {
                event.preventDefault()
                validateNewsletter()    
            })
        };     
    });
})(window, document);